import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import Navbar from "@/components/Navbar";
import ArticleHeader from "@/components/newsletter/ArticleHeader";
import ArticleNavigation from "@/components/newsletter/ArticleNavigation";
import ArticleError from "@/components/newsletter/ArticleError";
import ArticleLoading from "@/components/newsletter/ArticleLoading";
import BackToTop from "@/components/BackToTop";
import ReactMarkdown from 'react-markdown';
import { useEffect } from "react";

const NewsletterArticle = () => {
  const { articleNumber } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [articleNumber]);

  const {
    data: article,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["newsletter", articleNumber],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("newsletters")
        .select("*")
        .eq("article_number", parseInt(articleNumber || "0", 10))
        .eq("status", "published")
        .maybeSingle();

      if (error) throw error;
      return data;
    },
  });

  const { data: navigationData } = useQuery({
    queryKey: ["newsletter-navigation", articleNumber],
    queryFn: async () => {
      const { data: prevArticle } = await supabase
        .from("newsletters")
        .select("article_number, title, publication_date")
        .eq("status", "published")
        .lt("publication_date", article?.publication_date || "")
        .order("publication_date", { ascending: false })
        .limit(1)
        .maybeSingle();

      const { data: nextArticle } = await supabase
        .from("newsletters")
        .select("article_number, title, publication_date")
        .eq("status", "published")
        .gt("publication_date", article?.publication_date || "")
        .order("publication_date", { ascending: true })
        .limit(1)
        .maybeSingle();

      return { prevArticle, nextArticle };
    },
    enabled: !!article?.publication_date,
  });

  if (isLoading) {
    return <ArticleLoading />;
  }

  if (error || !article) {
    return <ArticleError />;
  }

  return (
    <div className="min-h-screen bg-offwhite">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="container max-w-4xl mx-auto px-4">
          <ArticleHeader
            title={article.title}
            publicationDate={article.publication_date}
          />

          <div className="prose">
            {article.content_format === 'html' ? (
              <div 
                dangerouslySetInnerHTML={{ __html: article.content }}
                className="prose"
              />
            ) : (
              <ReactMarkdown className="prose">
                {article.content}
              </ReactMarkdown>
            )}
          </div>

          <ArticleNavigation
            prevArticle={navigationData?.prevArticle}
            nextArticle={navigationData?.nextArticle}
          />
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default NewsletterArticle;