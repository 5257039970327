import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Send } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { subscribeToNewsletter } from "@/utils/newsletter";

export const NewsletterSignup = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast({
        title: "Please Review",
        description: "Please enter your email address.",
        variant: "destructive",
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast({
        title: "Please Review",
        description: "Please enter a valid email address.",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await subscribeToNewsletter(email, 'footer');
      toast({
        description: "Thank you for subscribing to our newsletter!",
        className: "bg-emerald text-white border-none",
      });
      setEmail("");
    } catch (error: any) {
      if (error.message.includes("already subscribed")) {
        toast({
          title: "Please Review",
          description: "This email is already subscribed to our newsletter",
          className: "bg-slate text-white border-none",
        });
      } else {
        toast({
          title: "Please Review",
          description: error.message,
          variant: "destructive",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative">
      <h2 className="mb-4 text-3xl font-playfair font-bold tracking-tight text-navy">
        Stay Connected
      </h2>
      <p className="mb-6 text-slate">
        Join our newsletter for our latest insights and company announcements.
      </p>
      <form onSubmit={handleSubmit} className="relative">
        <Input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="pr-12 backdrop-blur-sm"
          disabled={isSubmitting}
        />
        <Button
          type="submit"
          size="icon"
          disabled={isSubmitting}
          className="absolute right-1 top-1 h-8 w-8 rounded-full bg-emerald text-white transition-transform hover:scale-105 disabled:opacity-50"
        >
          <Send className="h-4 w-4" />
          <span className="sr-only">Subscribe</span>
        </Button>
      </form>
      <div className="absolute -right-4 top-0 h-24 w-24 rounded-full bg-emerald/10 blur-2xl" />
    </div>
  );
};