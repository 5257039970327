import { TooltipProps } from "recharts";

export const CustomTooltip = ({ 
  active, 
  payload, 
  label 
}: TooltipProps<number, string>) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  return (
    <div className="bg-white p-2 border border-lightgray rounded shadow-sm">
      <p className="text-xs text-slate">{payload[0].value?.toFixed(2)}</p>
    </div>
  );
};