import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Card, CardContent } from "@/components/ui/card";

const founders = [
  {
    name: "David Bailin",
    title: "Founder, CEO and CIO",
    image: "https://njbtoffvrbhvoemdivra.supabase.co/storage/v1/object/public/team-photos/DB.png",
    bio: "David brings decades of industry leadership to the CIO Capital Group, with a distinguished track record at Citigroup, where he pioneered award-winning investment strategies and oversaw the movement of over $20 billion in client assets as the Head of Investments and Chief Investment Officer. Known for his disciplined approach and market insights, David has been a trusted voice in wealth management, frequently featured on CNBC, Bloomberg, and in publications like the Wall Street Journal and Financial Times. At the CIO Capital Group, he combines a legacy of innovation with a commitment to transformative, data-driven wealth solutions tailored to family offices and UHNW clients.",
  },
  {
    name: "Abraham Ouano",
    title: "Co-Founder & General Partner",
    image: "https://njbtoffvrbhvoemdivra.supabase.co/storage/v1/object/public/team-photos/AO.png",
    bio: "Abraham is an accomplished investment leader with a wealth of experience from Citigroup, where he led the Global Investment Lab and firm-wide Transformation initiatives. There, he developed the award-winning IDEA platform, which delivered tailored portfolio construction and risk analytics for clients across Citigroup's global base. Under his leadership, IDEA earned multiple industry accolades, including recognition for Best Digital Platform for Relationship Managers and Best Use of AI and Big Data. At the CIO Capital Group, Abraham brings this expertise to create scalable, data-driven wealth solutions for family offices and UHNW clients.",
  },
];

const Team = () => {
  return (
    <section id="team" className="py-16 bg-offwhite">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-playfair font-bold text-center mb-12 text-[#1D3557]">
          Our Team
        </h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {founders.map((founder) => (
            <Dialog key={founder.name}>
              <DialogTrigger asChild>
                <Card className="cursor-pointer hover:shadow-lg transition-shadow">
                  <CardContent className="p-6">
                    <div className="aspect-square w-48 mx-auto mb-4 overflow-hidden rounded-full">
                      <img
                        src={founder.image}
                        alt={founder.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h3 className="text-2xl font-playfair text-[#1D3557] mb-2">
                      {founder.name}
                    </h3>
                    <p className="text-[#6C757D]">{founder.title}</p>
                  </CardContent>
                </Card>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle className="font-playfair text-[#1D3557]">
                    {founder.name}
                  </DialogTitle>
                  <DialogDescription className="text-[#6C757D]">
                    {founder.title}
                  </DialogDescription>
                </DialogHeader>
                <div className="mt-4">
                  <p className="text-[#6C757D] leading-relaxed">{founder.bio}</p>
                </div>
              </DialogContent>
            </Dialog>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;