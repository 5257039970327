import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

export interface MaintenanceStatus {
  inMaintenance: boolean;
  isLoading: boolean;
}

export const useMaintenanceMode = (): MaintenanceStatus => {
  const [inMaintenance, setInMaintenance] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkMaintenanceMode = async () => {
      try {
        const { data, error } = await supabase
          .from("site_settings")
          .select("maintenance_mode")
          .single();

        if (error) {
          console.error("Error fetching maintenance mode:", error);
          setInMaintenance(false);
        } else {
          setInMaintenance(data?.maintenance_mode || false);
        }
      } catch (error) {
        console.error("Error in maintenance check:", error);
        setInMaintenance(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkMaintenanceMode();

    // Subscribe to realtime changes
    const channel = supabase
      .channel("site_settings_changes")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "site_settings",
        },
        (payload: any) => {
          setInMaintenance(payload.new.maintenance_mode);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return { inMaintenance, isLoading };
};