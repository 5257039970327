import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useContactFormState } from "@/hooks/use-contact-form-state";
import { validateForm } from "@/utils/contact-validation";

interface ContactFormProps {
  onSubmitSuccess?: () => void;
}

const ContactForm = ({ onSubmitSuccess }: ContactFormProps) => {
  const { toast } = useToast();
  const {
    isSubmitting,
    setIsSubmitting,
    formData,
    handleChange,
    resetForm,
  } = useContactFormState();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validateForm(formData);
    if (!validation.isValid) {
      toast({
        description: validation.error,
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const submissionData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone || null,
        message: formData.message,
      };

      const { error } = await supabase
        .from("contact_submissions")
        .insert(submissionData);

      if (error) throw error;

      const shouldShowToast = true;

      resetForm();

      if (shouldShowToast) {
        await new Promise<void>((resolve) => {
          toast({
            description: "Thank you for your message. We'll get back to you soon!",
            className: "bg-emerald text-white border-none",
          });
          setTimeout(resolve, 100);
        });
      }

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }

    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Error",
        description: "There was a problem sending your message. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <h2 className="text-2xl font-playfair font-bold text-navy mb-6">Contact Us</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              className="form-input"
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              className="form-input"
              required
            />
          </div>
        </div>
        <div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="form-input"
            required
          />
        </div>
        <div>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone (optional)"
            className="form-input"
          />
        </div>
        <div>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your Message"
            rows={4}
            className="form-textarea"
            required
          />
        </div>
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-emerald text-white py-3 rounded-lg hover:bg-emerald/80 transition-colors disabled:opacity-50 text-base min-h-[44px]"
        >
          {isSubmitting ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;