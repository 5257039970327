"use client"

import { NewsletterSignup } from "@/components/footer/NewsletterSignup";
import { QuickLinks } from "@/components/footer/QuickLinks";
import { ContactInfo } from "@/components/footer/ContactInfo";
import { SocialLinks } from "@/components/footer/SocialLinks";
import { DisclaimerDialog } from "@/components/footer/DisclaimerDialog";

export function Footerdemo() {
  return (
    <footer className="relative border-t bg-background text-foreground font-roboto transition-colors duration-300">
      <div className="container mx-auto px-4 py-12 md:px-6 lg:px-8">
        <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-4">
          <NewsletterSignup />
          <QuickLinks />
          <ContactInfo />
          <SocialLinks />
        </div>
        <div className="mt-12 flex flex-col items-center justify-between gap-4 border-t pt-8 text-center md:flex-row">
          <p className="text-sm text-slate">
            © 2025 CIO Capital Group. All rights reserved.
          </p>
          <nav className="flex gap-4 text-sm">
            <DisclaimerDialog />
          </nav>
        </div>
      </div>
    </footer>
  );
}