interface SectionProps {
  title: string;
  children: React.ReactNode;
}

export const Section = ({ title, children }: SectionProps) => {
  return (
    <section>
      <h2 className="text-2xl font-playfair font-bold text-navy mb-6">{title}</h2>
      {children}
    </section>
  );
};