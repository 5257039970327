import { useEffect, useState } from "react";
import { Section } from "./Section";
import { DataCard } from "./DataCard";
import { YieldsCard } from "./YieldsCard";

export const MarketIndicators = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        // Simulated data for now - replace with actual API calls
        setData({
          sp500: {
            latest: 4780.5,
            change: 1.2,
            historical: Array.from({ length: 10 }, (_, i) => ({
              date: new Date(Date.now() - i * 86400000).toISOString(),
              value: 4700 + Math.random() * 100
            }))
          },
          yields: {
            twoYear: 4.2,
            fiveYear: 4.0,
            tenYear: 3.8
          }
        });
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };
    
    fetchMarketData();
  }, []);

  return (
    <Section title="Market Overview">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DataCard
          title="S&P 500"
          value={data?.sp500.latest}
          change={data?.sp500.change}
          chart={data?.sp500.historical}
          source="Yahoo Finance"
        />
        
        <YieldsCard
          data={data?.yields}
          source="FRED"
        />
      </div>
    </Section>
  );
};