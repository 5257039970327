import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastProvider } from "@/components/ui/toast";
import { Toaster } from "@/components/ui/toaster";
import RouteGuard from "./components/RouteGuard";
import Index from "./pages/Index";
import Admin from "./pages/Admin";
import AdminLogin from "./pages/AdminLogin";
import NewsletterArticle from "./pages/NewsletterArticle";
import MarketBarometer from "./pages/MarketBarometer";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Router>
          <RouteGuard>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/newsletter/:articleNumber" element={<NewsletterArticle />} />
              <Route path="/market-barometer" element={<MarketBarometer />} />
              {/* Catch all route - redirect to home */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </RouteGuard>
        </Router>
        <Toaster />
      </ToastProvider>
    </QueryClientProvider>
  );
}

export default App;