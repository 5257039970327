import { useEffect } from "react";
import Navbar from "@/components/Navbar";
import Hero from "@/components/Hero";
import Values from "@/components/Values";
import Newsletter from "@/components/Newsletter";
import Team from "@/components/Team";
import BackToTop from "@/components/BackToTop";
import Footer from "@/components/Footer";
import { Toaster } from "@/components/ui/toaster";

const Index = () => {
  useEffect(() => {
    // Reset scroll position on initial page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white font-roboto">
      <Navbar />
      <Hero />
      <Values />
      <Newsletter />
      <Team />
      <BackToTop />
      <Footer />
      <Toaster />
    </div>
  );
};

export default Index;