import { useContactForm } from "@/hooks/use-contact-form";
import Contact from "@/components/Contact";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";

export const QuickLinks = () => {
  const { isOpen: contactIsOpen, setOpen: setContactOpen } = useContactForm();

  return (
    <div>
      <h3 className="mb-4 text-lg font-playfair font-semibold text-navy">Quick Links</h3>
      <nav className="space-y-2 text-sm">
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          className="block transition-colors hover:text-emerald"
        >
          About Us
        </button>
        <button
          onClick={() => {
            document.getElementById('values')?.scrollIntoView({ behavior: 'smooth' });
          }}
          className="block transition-colors hover:text-emerald"
        >
          Values
        </button>
        <button
          onClick={() => {
            document.getElementById('newsletter')?.scrollIntoView({ behavior: 'smooth' });
          }}
          className="block transition-colors hover:text-emerald"
        >
          Insights
        </button>
        <button
          onClick={() => {
            document.getElementById('team')?.scrollIntoView({ behavior: 'smooth' });
          }}
          className="block transition-colors hover:text-emerald"
        >
          Team
        </button>
        <Sheet open={contactIsOpen} onOpenChange={setContactOpen}>
          <SheetTrigger asChild>
            <button className="block transition-colors hover:text-emerald">
              Contact
            </button>
          </SheetTrigger>
          <SheetContent className="w-full sm:max-w-[400px] bg-offwhite overflow-y-auto">
            <div className="h-full py-6">
              <Contact />
            </div>
          </SheetContent>
        </Sheet>
      </nav>
    </div>
  );
};