import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Tables } from "@/integrations/supabase/types";

type Newsletter = Tables<"newsletters">;

interface NewsletterGridProps {
  articles: Newsletter[] | null;
}

const NewsletterGrid = ({ articles }: NewsletterGridProps) => {
  if (!articles || articles.length === 0) {
    return (
      <div className="text-center text-gray-500">
        No articles found.
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {articles.map((article) => (
        <Link
          key={article.id}
          to={`/newsletter/${article.article_number}`}
          className="group"
        >
          <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 group-hover:-translate-y-1">
            <div className="p-6">
              <h3 className="text-xl font-playfair font-bold text-navy mb-2 group-hover:text-emerald transition-colors">
                {article.title}
              </h3>
              <p className="text-sm text-slate mb-4">
                {format(new Date(article.publication_date || ""), "MMMM d, yyyy")}
              </p>
              <p className="text-slate line-clamp-3">{article.description}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default NewsletterGrid;