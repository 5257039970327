import { useEffect, useState } from "react";
import { BarometerHeader } from "@/components/market/BarometerHeader";
import { MacroIndicators } from "@/components/market/MacroIndicators";
import { MarketIndicators } from "@/components/market/MarketIndicators";
import { CommoditiesSection } from "@/components/market/CommoditiesSection";
import { CryptoAndFX } from "@/components/market/CryptoAndFX";

const MarketBarometer = () => {
  return (
    <div className="min-h-screen bg-offwhite">
      <div className="container mx-auto px-4 py-12">
        <BarometerHeader />
        
        <div className="space-y-16">
          <MacroIndicators />
          <MarketIndicators />
          <CommoditiesSection />
          <CryptoAndFX />
        </div>
      </div>
    </div>
  );
};

export default MarketBarometer;