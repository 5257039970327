import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import Contact from "@/components/Contact";
import { useContactForm } from "@/hooks/use-contact-form";

interface ContactSheetProps {
  className?: string;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const ContactSheet = ({ className, isOpen, onOpenChange }: ContactSheetProps) => {
  const { isOpen: internalOpen, setOpen, handleFormSubmitted } = useContactForm();
  
  // Use controlled or uncontrolled state based on props
  const open = isOpen !== undefined ? isOpen : internalOpen;
  const handleOpenChange = onOpenChange || setOpen;

  return (
    <Sheet open={open} onOpenChange={handleOpenChange}>
      <SheetTrigger asChild>
        <button 
          className={`font-roboto hover:bg-emerald/80 hover:text-white px-4 py-2 rounded-lg transition-all duration-300 focus:outline-none ${className}`}
        >
          Contact
        </button>
      </SheetTrigger>
      <SheetContent 
        className="w-full sm:max-w-[400px] p-0 bg-offwhite overscroll-contain"
        onOpenAutoFocus={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <div className="h-full overflow-y-auto touch-pan-y overscroll-contain">
          <div className="p-6">
            <Contact onSubmitSuccess={handleFormSubmitted} />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ContactSheet;