import { useState } from "react";

export const useContactForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFormSubmitted = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    setOpen: setIsOpen,
    handleFormSubmitted
  };
};