import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

export const DisclaimerDialog = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="link" className="text-sm text-navy hover:text-emerald transition-colors">
          Disclaimer
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="font-playfair text-[#1D3557]">
            Disclaimer
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-[#6C757D] leading-relaxed">
          <div className="space-y-4 text-left">
            <p>
              General Disclaimer and Release. Nothing contained herein constitutes tax, legal, insurance or investment advice, or the recommendation of or an offer to sell, or the solicitation of an offer to buy or invest in any investment product, vehicle, service or instrument. Such an offer or solicitation may only be made by delivery to a prospective investor of formal offering materials, including subscription or account documents or forms, which include detailed discussions of the terms of the respective product, vehicle, service or instrument, including the principal risk factors that might impact such a purchase or investment, and which should be reviewed carefully by any such investor before making the decision to invest.
            </p>
            <p>
              CIO Capital Group information may include statements concerning financial market trends and/or individual stocks, and are based on current market conditions, which will fluctuate and may be superseded by subsequent market events or for other reasons. Historic market trends are not reliable indicators of actual future market behavior or future performance of any particular investment which may differ materially, and should not be relied upon as such.
            </p>
            <p>
              The investment strategy and broad themes discussed herein may be inappropriate for investors depending on their specific investment objectives and financial situation. Information contained in the material has been obtained from sources believed to be reliable, but not guaranteed. You should note that the materials are provided "as is" without any express or implied warranties.
            </p>
            <p>
              Past performance is not a guarantee of future results. All investments involve a degree of risk, including the risk of loss. No part of CIO Capital Group's materials may be reproduced in any form, or referred to in any other publication, without express written permission from CIO Capital Group.
            </p>
            <p>
              Links to appearances and articles by employees of CIO Capital Group, whether in the press, on television or otherwise, are provided for informational purposes only and in no way should be considered a recommendation of any particular investment product, vehicle, service or instrument or the rendering of investment advice, which must always be evaluated by a prospective investor in consultation with his or her own financial adviser and in light of his or her own circumstances, including the investor's investment horizon, appetite for risk, and ability to withstand a potential loss of some or all of an investment's value.
            </p>
            <p>
              Graphs, charts, and tables are provided for illustrative purposes only. Investing is subject to market risks. Investors acknowledge and accept the potential loss of some or all of an investment's value. Views represented are subject to change at the sole discretion of CIO Capital Group LLC. CIO Capital Group LLC does not undertake to advise you of any changes in the views expressed herein.
            </p>
            <p>
              By using this website, you acknowledge that you have read and understand the foregoing disclaimers and release CIO Capital Group LLC and its affiliates, members, officers, employees and agents from any and all liability whatsoever relating to your use of this site, any such links, or any information contained herein or in any such appearances or articles (whether accessed through such links or downloaded directly from this website).
            </p>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};