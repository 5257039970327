interface NewsletterHeaderProps {
  title: string;
  description: string;
}

const NewsletterHeader = ({ title, description }: NewsletterHeaderProps) => {
  return (
    <>
      <h2 className="text-4xl font-playfair font-bold text-navy text-center mb-6">
        {title}
      </h2>
      <p className="text-slate max-w-3xl mx-auto text-center text-justify mb-16 leading-relaxed">
        {description}
      </p>
    </>
  );
};

export default NewsletterHeader;