import { useEffect, useState } from "react";
import { Section } from "./Section";
import { DataCard } from "./DataCard";

export const CommoditiesSection = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchCommoditiesData = async () => {
      try {
        // Simulated data for now - replace with actual API calls
        setData({
          oil: {
            latest: 75.8,
            change: -0.5,
            historical: Array.from({ length: 10 }, (_, i) => ({
              date: new Date(Date.now() - i * 86400000).toISOString(),
              value: 75 + Math.random() * 2
            }))
          }
        });
      } catch (error) {
        console.error("Error fetching commodities data:", error);
      }
    };
    
    fetchCommoditiesData();
  }, []);

  return (
    <Section title="Commodities">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DataCard
          title="Crude Oil (WTI)"
          value={data?.oil.latest}
          change={data?.oil.change}
          chart={data?.oil.historical}
          source="Yahoo Finance"
          format=" USD"
        />
      </div>
    </Section>
  );
};