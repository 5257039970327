import { ContactFormData } from "@/hooks/use-contact-form-state";

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPhone = (phone: string) => {
  const phoneRegex = /^\+?[\d\s-()]{10,}$/;
  return phone === "" || phoneRegex.test(phone);
};

export const validateForm = (formData: ContactFormData) => {
  if (!formData.firstName.trim()) {
    return { isValid: false, error: "Please enter your first name" };
  }
  if (!formData.lastName.trim()) {
    return { isValid: false, error: "Please enter your last name" };
  }
  if (!formData.email.trim() || !isValidEmail(formData.email)) {
    return { isValid: false, error: "Please enter a valid email address" };
  }
  if (formData.phone.trim() && !isValidPhone(formData.phone)) {
    return { isValid: false, error: "Please enter a valid phone number" };
  }
  if (!formData.message.trim()) {
    return { isValid: false, error: "Please enter your message" };
  }
  return { isValid: true, error: null };
};