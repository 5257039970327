import { useSearchParams } from "react-router-dom";

export const useNewsletterPagination = (totalPages: number) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || "1");

  const handlePageChange = (page: number) => {
    setSearchParams({ page: page.toString() }, { replace: true });
    const newsletterSection = document.getElementById("newsletter");
    if (newsletterSection) {
      newsletterSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return {
    currentPage,
    totalPages,
    handlePageChange,
  };
};