import { useState } from "react";

const values = [
  {
    title: "Think Holistically",
    description:
      "We consider all aspects of our clients' financial landscape to create comprehensive solutions.",
  },
  {
    title: "Measure Impartially",
    description:
      "Our independent status allows us to evaluate opportunities without bias.",
  },
  {
    title: "Allocate Optimally",
    description:
      "We strategically position assets to maximize potential while managing risk.",
  },
  {
    title: "Improve Continuously",
    description:
      "We constantly evolve our approach to stay ahead of market dynamics.",
  },
];

const Values = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <section id="values" className="py-20 bg-offwhite">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-playfair font-bold text-navy text-center mb-16">
          Our Values
        </h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {values.map((value, index) => (
            <div
              key={value.title}
              className="relative group"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                className={`bg-white p-8 rounded-lg shadow-sm transition-all duration-300 ${
                  hoveredIndex === index ? "transform -translate-y-2" : ""
                }`}
              >
                <h3 className="text-2xl font-playfair font-bold text-navy mb-4">
                  {value.title}
                </h3>
                <p
                  className={`text-slate font-roboto leading-relaxed transition-opacity duration-300 ${
                    hoveredIndex === index ? "opacity-100" : "opacity-70"
                  }`}
                >
                  {value.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Values;