import { useState } from "react";
import { ArrowRight } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { subscribeToNewsletter } from "@/utils/newsletter";

const Hero = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast({
        title: "Please Review",
        description: "Please enter your email address.",
        variant: "destructive",
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast({
        title: "Please Review",
        description: "Please enter a valid email address.",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await subscribeToNewsletter(email, 'hero');
      toast({
        description: "Thank you for subscribing to our newsletter!",
        className: "bg-emerald text-white border-none",
      });
      setEmail("");
    } catch (error: any) {
      if (error.message.includes("already subscribed")) {
        toast({
          title: "Please Review",
          description: "This email is already subscribed to our newsletter",
          className: "bg-slate text-white border-none",
        });
      } else {
        toast({
          title: "Please Review",
          description: error.message,
          variant: "destructive",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="min-h-screen flex items-center relative bg-gradient-to-br from-navy to-slate text-white py-20">
      <div className="absolute inset-0 bg-[url('/placeholder.svg')] opacity-10" />
      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="font-playfair text-5xl md:text-7xl font-bold mb-8 animate-fadeIn">
            Elevated Independence
          </h1>
          <p className="font-roboto text-lg md:text-xl mb-12 leading-relaxed">
            CIO Capital Group delivers sophisticated investment solutions through
            independent thinking and innovative strategies. We partner with clients
            to achieve their unique financial objectives.
          </p>
          <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="flex-1 px-6 py-3 rounded bg-white/10 border border-white/20 text-white placeholder:text-white/60 focus:outline-none focus:ring-2 focus:ring-emerald"
                disabled={isSubmitting}
                required
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-emerald hover:bg-opacity-90 text-white px-8 py-3 rounded flex items-center justify-center gap-2 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Subscribe
                <ArrowRight size={20} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Hero;