import { useState, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '@/styles/editor.css';

interface NewsletterFormData {
  title: string;
  description: string;
  content: string;
  publication_date: string;
}

interface NewsletterFormProps {
  initialData?: {
    id: string;
    title: string;
    description: string;
    content: string;
    publication_date: string | null;
    content_format: string;
  };
  onSuccess?: () => void;
}

async function handleImageUpload(file: File): Promise<string> {
  try {
    const fileExt = file.name.split('.').pop();
    const fileName = `${crypto.randomUUID()}.${fileExt}`;
    const filePath = `newsletter-images/${fileName}`;

    const { error: uploadError } = await supabase.storage
      .from('newsletter-images')
      .upload(filePath, file);

    if (uploadError) throw uploadError;

    const { data: { publicUrl } } = supabase.storage
      .from('newsletter-images')
      .getPublicUrl(filePath);

    return publicUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}

const modules = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['image'],
      ['clean']
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'header',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'image'
];

export function NewsletterForm({ initialData, onSuccess }: NewsletterFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [editorContent, setEditorContent] = useState(initialData?.content || '');
  const quillRef = useRef<ReactQuill>(null);

  const form = useForm<NewsletterFormData>({
    defaultValues: {
      title: initialData?.title || "",
      description: initialData?.description || "",
      content: initialData?.content || "",
      publication_date: initialData?.publication_date?.split("T")[0] || "",
    },
  });

  const handleEditorChange = useCallback((content: string) => {
    setEditorContent(content);
    form.setValue("content", content);
  }, [form]);

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0];
      if (file) {
        try {
          const url = await handleImageUpload(file);
          const editor = quillRef.current?.getEditor();
          if (editor) {
            const range = editor.getSelection(true);
            editor.insertEmbed(range.index, 'image', url);
          }
        } catch (error) {
          console.error('Error handling image upload:', error);
          toast({
            title: "Error",
            description: "Failed to upload image. Please try again.",
            variant: "destructive",
          });
        }
      }
    };
  }, [toast]);

  const onSubmit = async (data: NewsletterFormData) => {
    setIsSubmitting(true);
    try {
      if (initialData?.id) {
        const { error } = await supabase
          .from("newsletters")
          .update({
            title: data.title,
            description: data.description,
            content: editorContent,
            content_format: 'html',
            publication_date: data.publication_date || null,
            updated_at: new Date().toISOString(),
          })
          .eq("id", initialData.id);

        if (error) throw error;

        toast({
          title: "Newsletter Updated",
          description: "The newsletter has been updated successfully.",
        });
      } else {
        const { error } = await supabase.from("newsletters").insert({
          title: data.title,
          description: data.description,
          content: editorContent,
          content_format: 'html',
          publication_date: data.publication_date || null,
          status: "draft",
        });

        if (error) throw error;

        toast({
          title: "Newsletter Created",
          description: "The newsletter has been created successfully.",
        });
      }

      await queryClient.invalidateQueries({ queryKey: ["newsletters"] });
      
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error("Error saving newsletter:", error);
      toast({
        title: "Error",
        description: "There was an error saving the newsletter.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Enter newsletter title" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter a brief description"
                  className="h-20"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Content</FormLabel>
              <FormControl>
                <div className="min-h-[500px]">
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={editorContent}
                    onChange={handleEditorChange}
                    modules={{
                      ...modules,
                      toolbar: {
                        ...modules.toolbar,
                        handlers: {
                          image: imageHandler
                        }
                      }
                    }}
                    formats={formats}
                    style={{ height: '400px' }}
                  />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="publication_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Publication Date</FormLabel>
              <FormControl>
                <Input type="date" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end gap-4">
          <Button
            type="button"
            variant="outline"
            onClick={() => onSuccess?.()}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting
              ? "Saving..."
              : initialData
              ? "Update Newsletter"
              : "Create Newsletter"}
          </Button>
        </div>
      </form>
    </Form>
  );
}
