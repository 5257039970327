import { useLocation } from "react-router-dom";

interface MobileMenuProps {
  isScrolled: boolean;
  isArticlePage: boolean;
  onNavigate: (sectionId: string | null) => void;
  onContactClick: () => void;
}

const MobileMenu = ({
  isScrolled,
  isArticlePage,
  onNavigate,
  onContactClick,
}: MobileMenuProps) => {
  const navItemClass = `
    font-roboto px-4 py-2 rounded-lg transition-all duration-300
    ${isScrolled || isArticlePage ? "text-navy" : "text-white"}
    hover:bg-emerald/80 hover:text-white
  `;

  const mobileMenuGlassEffect = isArticlePage
    ? "bg-white/80"
    : isScrolled 
      ? "bg-white/80" 
      : "bg-navy/80";

  return (
    <div className={`mt-4 pb-4 space-y-2 flex flex-col items-end rounded-lg p-4 ${mobileMenuGlassEffect}`}>
      <button
        onClick={() => onNavigate(null)}
        className={navItemClass}
      >
        About Us
      </button>
      <button
        onClick={() => onNavigate('values')}
        className={navItemClass}
      >
        Values
      </button>
      <button
        onClick={() => onNavigate('newsletter')}
        className={navItemClass}
      >
        Insights
      </button>
      <button
        onClick={() => onNavigate('team')}
        className={navItemClass}
      >
        Team
      </button>
      <button 
        onClick={onContactClick}
        className={`font-roboto hover:bg-emerald/80 hover:text-white px-4 py-2 rounded-lg transition-all duration-300 focus:outline-none ${isScrolled || isArticlePage ? "text-navy" : "text-white"}`}
      >
        Contact
      </button>
    </div>
  );
};

export default MobileMenu;