import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import Navbar from "@/components/Navbar";

const ArticleLoading = () => {
  return (
    <div className="min-h-screen bg-offwhite">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="container max-w-4xl mx-auto px-4">
          <div className="mb-8">
            <Link
              to="/"
              className="inline-flex items-center text-emerald hover:text-emerald/90 mb-8"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to Newsletters
            </Link>
            
            <div className="space-y-4">
              <Skeleton className="w-3/4 h-12 bg-gradient-to-r from-lightgray to-offwhite" />
              <Skeleton className="w-32 h-4 bg-gradient-to-r from-lightgray to-offwhite" />
            </div>
          </div>

          <div className="space-y-6">
            <Skeleton className="w-full h-32 bg-gradient-to-r from-lightgray to-offwhite" />
            <Skeleton className="w-full h-32 bg-gradient-to-r from-lightgray to-offwhite" />
            <Skeleton className="w-5/6 h-32 bg-gradient-to-r from-lightgray to-offwhite" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleLoading;