import { ArrowLeft } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "@/components/Navbar";

const ArticleError = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-offwhite">
      <Navbar />
      <div className="pt-24 pb-16">
        <div className="container max-w-4xl mx-auto px-4">
          <Link
            to="/"
            className="inline-flex items-center text-emerald hover:text-emerald/90 mb-8"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Newsletters
          </Link>
          <div className="text-center py-16">
            <h1 className="text-2xl font-playfair text-navy mb-4">
              Article Not Found
            </h1>
            <p className="text-slate mb-8">
              The article you're looking for doesn't exist or has been removed.
            </p>
            <button
              onClick={() => navigate("/")}
              className="text-emerald hover:text-emerald/90"
            >
              Return to Newsletter Index
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleError;