export const BarometerHeader = () => {
  return (
    <div className="mb-12">
      <h1 className="text-4xl font-playfair font-bold text-navy mb-4">
        Market Barometer
      </h1>
      <p className="text-slate max-w-3xl">
        A comprehensive overview of key economic indicators, market performance, commodities, and currency trends.
      </p>
    </div>
  );
};