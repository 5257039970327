import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import Contact from "@/components/Contact";
import { useContactForm } from "@/hooks/use-contact-form";

export const ContactInfo = () => {
  const { isOpen: contactIsOpen, setOpen: setContactOpen } = useContactForm();

  return (
    <div>
      <Sheet open={contactIsOpen} onOpenChange={setContactOpen}>
        <SheetTrigger asChild>
          <h3 className="mb-4 text-lg font-playfair font-semibold text-navy cursor-pointer hover:text-emerald transition-colors">
            Contact Us
          </h3>
        </SheetTrigger>
        <SheetContent className="w-full sm:max-w-[400px] bg-offwhite overflow-y-auto">
          <div className="h-full py-6">
            <Contact />
          </div>
        </SheetContent>
      </Sheet>
      <address className="space-y-2 text-sm not-italic text-slate">
        <p>228 Park Ave S</p>
        <p>New York, NY 10003</p>
        <p>Email: info@ciocapitalgroup.com</p>
      </address>
    </div>
  );
};
