const NewsletterSkeleton = () => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[...Array(6)].map((_, i) => (
        <div
          key={i}
          className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow animate-pulse"
        >
          <div className="p-6">
            <div className="h-6 bg-slate-200 rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-slate-200 rounded w-full mb-4"></div>
            <div className="h-4 bg-slate-200 rounded w-full mb-4"></div>
            <div className="flex justify-between items-center">
              <div className="h-4 bg-slate-200 rounded w-1/4"></div>
              <div className="h-4 bg-slate-200 rounded w-1/4"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsletterSkeleton;