export const scrollToSection = (sectionId: string | null, pathname: string, navigate: (path: string) => void) => {
  if (pathname !== '/') {
    navigate('/');
    setTimeout(() => {
      if (sectionId === null) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 100);
  } else {
    if (sectionId === null) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};