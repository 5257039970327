import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

export interface AuthStatus {
  isAdmin: boolean;
  isLoading: boolean;
}

export const useAuthStatus = (): AuthStatus => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          const { data: adminUser } = await supabase
            .from("admin_users")
            .select("*")
            .eq("id", session.user.id)
            .single();
          setIsAdmin(!!adminUser);
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
        setIsAdmin(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  return { isAdmin, isLoading };
};