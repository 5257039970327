import { useState, useEffect } from "react";
import { Switch } from "@/components/ui/switch";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

export function MaintenanceToggle() {
  const [isEnabled, setIsEnabled] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      const { data, error } = await supabase
        .from("site_settings")
        .select("maintenance_mode")
        .single();

      if (!error && data) {
        setIsEnabled(data.maintenance_mode);
      }
    };

    fetchMaintenanceStatus();
  }, []);

  const toggleMaintenance = async (enabled: boolean) => {
    const { error } = await supabase
      .from("site_settings")
      .update({ maintenance_mode: enabled })
      .eq("id", (await supabase.from("site_settings").select("id").single()).data?.id);

    if (!error) {
      setIsEnabled(enabled);
      toast({
        title: enabled ? "Maintenance Mode Enabled" : "Maintenance Mode Disabled",
        description: enabled
          ? "The site is now in maintenance mode."
          : "The site is now accessible to all users.",
      });
    } else {
      toast({
        title: "Error",
        description: "Failed to update maintenance mode.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="p-4 border rounded-lg bg-white">
      <h3 className="text-lg font-playfair mb-4">Maintenance Mode</h3>
      <div className="flex items-center justify-between">
        <span>Enable Maintenance Mode</span>
        <Switch checked={isEnabled} onCheckedChange={toggleMaintenance} />
      </div>
    </div>
  );
}