import { useEffect, useState } from "react";
import { Section } from "./Section";
import { DataCard } from "./DataCard";

export const CryptoAndFX = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchCryptoFXData = async () => {
      try {
        // Simulated data for now - replace with actual API calls
        setData({
          btc: {
            latest: 42000,
            change: 2.3,
            historical: Array.from({ length: 10 }, (_, i) => ({
              date: new Date(Date.now() - i * 86400000).toISOString(),
              value: 41000 + Math.random() * 2000
            }))
          }
        });
      } catch (error) {
        console.error("Error fetching crypto/fx data:", error);
      }
    };
    
    fetchCryptoFXData();
  }, []);

  return (
    <Section title="Crypto & FX">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DataCard
          title="Bitcoin"
          value={data?.btc.latest}
          change={data?.btc.change}
          chart={data?.btc.historical}
          source="Yahoo Finance"
          format=" USD"
        />
      </div>
    </Section>
  );
};