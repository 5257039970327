interface YieldsData {
  twoYear: number;
  fiveYear: number;
  tenYear: number;
}

interface YieldsCardProps {
  data: YieldsData | null;
  source: string;
}

export const YieldsCard = ({ data, source }: YieldsCardProps) => {
  if (!data) return null;

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-200">
      <div className="flex justify-between items-start mb-4">
        <h3 className="font-playfair text-navy text-lg">Treasury Yields</h3>
        <span className="text-xs text-slate-400">{source}</span>
      </div>

      <div className="space-y-3">
        <div className="flex justify-between items-center">
          <span className="text-slate">2Y</span>
          <span className="font-roboto">{data.twoYear.toFixed(2)}%</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-slate">5Y</span>
          <span className="font-roboto">{data.fiveYear.toFixed(2)}%</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-slate">10Y</span>
          <span className="font-roboto">{data.tenYear.toFixed(2)}%</span>
        </div>
      </div>
    </div>
  );
};