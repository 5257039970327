import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import NewsletterGrid from "./NewsletterGrid";
import NewsletterPagination from "./NewsletterPagination";
import NewsletterSkeleton from "./NewsletterSkeleton";
import { useNewsletterPagination } from "@/hooks/use-newsletter-pagination";

const ITEMS_PER_PAGE = 6;

const NewsletterContent = () => {
  const { currentPage, handlePageChange } = useNewsletterPagination(0);

  const { data, isLoading } = useQuery({
    queryKey: ["newsletters", currentPage],
    queryFn: async () => {
      const startRange = (currentPage - 1) * ITEMS_PER_PAGE;
      const endRange = startRange + ITEMS_PER_PAGE - 1;

      const { data: articles, count } = await supabase
        .from("newsletters")
        .select("*", { count: "exact" })
        .eq("status", "published")
        .order("publication_date", { ascending: false })
        .range(startRange, endRange);

      return { articles, totalCount: count || 0 };
    },
  });

  const totalPages = data ? Math.ceil(data.totalCount / ITEMS_PER_PAGE) : 0;

  if (isLoading) return <NewsletterSkeleton />;

  return (
    <>
      <NewsletterGrid articles={data?.articles} />
      <NewsletterPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default NewsletterContent;