import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Pencil, Trash2, Eye, Send } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { NewsletterForm } from "./NewsletterForm";
import { NewsletterPreview } from "./NewsletterPreview";
import { useToast } from "@/hooks/use-toast";

type Newsletter = {
  id: string;
  article_number: number;
  title: string;
  description: string | null;
  content: string;
  content_format: string;
  publication_date: string | null;
  status: "draft" | "published";
};

export function NewsletterTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNewsletter, setSelectedNewsletter] = useState<Newsletter | null>(
    null
  );
  const [previewNewsletter, setPreviewNewsletter] = useState<Newsletter | null>(
    null
  );
  const [isCreating, setIsCreating] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: newsletters, isLoading } = useQuery({
    queryKey: ["newsletters"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("newsletters")
        .select("*")
        .order("publication_date", { ascending: false });

      if (error) throw error;
      return data as Newsletter[];
    },
  });

  const publishMutation = useMutation({
    mutationFn: async ({ id, status }: { id: string; status: "draft" | "published" }) => {
      const { error } = await supabase
        .from("newsletters")
        .update({ status })
        .eq("id", id);

      if (error) throw error;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["newsletters"] });
      toast({
        title: `Article ${variables.status === "published" ? "Published" : "Unpublished"}`,
        description: `The article has been ${variables.status === "published" ? "published" : "unpublished"} successfully.`,
      });
    },
    onError: (error) => {
      console.error("Error publishing/unpublishing article:", error);
      toast({
        title: "Error",
        description: "There was an error updating the article status.",
        variant: "destructive",
      });
    },
  });

  const handlePublishToggle = (newsletter: Newsletter) => {
    const newStatus = newsletter.status === "draft" ? "published" : "draft";
    publishMutation.mutate({ id: newsletter.id, status: newStatus });
  };

  const filteredNewsletters = newsletters?.filter((newsletter) =>
    newsletter.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEdit = (newsletter: Newsletter) => {
    setSelectedNewsletter(newsletter);
  };

  const handlePreview = (newsletter: Newsletter) => {
    setPreviewNewsletter(newsletter);
  };

  const handleCreate = () => {
    setIsCreating(true);
  };

  const handleFormSuccess = () => {
    setSelectedNewsletter(null);
    setIsCreating(false);
  };

  if (selectedNewsletter || isCreating) {
    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-playfair font-bold text-navy">
            {isCreating ? "Create Newsletter" : "Edit Newsletter"}
          </h2>
        </div>
        <NewsletterForm
          initialData={selectedNewsletter || undefined}
          onSuccess={handleFormSuccess}
        />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Input
          placeholder="Search newsletters..."
          className="max-w-sm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button className="bg-emerald hover:bg-emerald/90" onClick={handleCreate}>
          Create New Article
        </Button>
      </div>

      <div className="border rounded-lg">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Article #</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>Publication Date</TableHead>
              <TableHead>Status</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : (
              filteredNewsletters?.map((newsletter) => (
                <TableRow key={newsletter.id}>
                  <TableCell>#{newsletter.article_number}</TableCell>
                  <TableCell>{newsletter.title}</TableCell>
                  <TableCell>
                    {newsletter.publication_date
                      ? format(
                          new Date(newsletter.publication_date),
                          "MMM d, yyyy"
                        )
                      : "Not scheduled"}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        newsletter.status === "published"
                          ? "bg-emerald/20 text-emerald"
                          : "bg-slate/20 text-slate"
                      }`}
                    >
                      {newsletter.status}
                    </span>
                  </TableCell>
                  <TableCell className="text-right">
                    <div className="flex justify-end gap-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0"
                        title="Preview"
                        onClick={() => handlePreview(newsletter)}
                      >
                        <Eye className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0"
                        title="Edit"
                        onClick={() => handleEdit(newsletter)}
                      >
                        <Pencil className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        className={`h-8 w-8 p-0 ${
                          newsletter.status === "published"
                            ? "text-emerald hover:text-emerald/90"
                            : "text-slate hover:text-slate/90"
                        }`}
                        title={newsletter.status === "published" ? "Unpublish" : "Publish"}
                        onClick={() => handlePublishToggle(newsletter)}
                      >
                        <Send className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0 text-red-500 hover:text-red-600"
                        title="Delete"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>

      <NewsletterPreview
        newsletter={previewNewsletter}
        open={!!previewNewsletter}
        onOpenChange={(open) => !open && setPreviewNewsletter(null)}
      />
    </div>
  );
}