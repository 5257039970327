import { ResponsiveContainer, LineChart, Line, YAxis, Tooltip } from "recharts";
import { CustomTooltip } from "./CustomTooltip";

interface ChartData {
  date: string;
  value: number;
}

interface MinimalChartProps {
  data: ChartData[];
}

export const MinimalChart = ({ data }: MinimalChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <Line 
          type="monotone" 
          dataKey="value" 
          stroke="#50C878" 
          strokeWidth={1.5}
          dot={false}
        />
        <YAxis 
          hide 
          domain={['auto', 'auto']}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ stroke: '#E9ECEF' }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};