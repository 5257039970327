import { supabase } from "@/integrations/supabase/client";

export const subscribeToNewsletter = async (email: string, source: 'hero' | 'footer') => {
  try {
    // First check if email exists
    const { data: existingSubscriber } = await supabase
      .from('newsletter_subscribers')
      .select('email')
      .eq('email', email)
      .maybeSingle();

    if (existingSubscriber) {
      throw new Error('This email is already subscribed to our newsletter');
    }

    // If email doesn't exist, proceed with subscription
    const { error } = await supabase
      .from('newsletter_subscribers')
      .insert({
        email: email,
        subscription_source: source
      });

    if (error) {
      console.error('Subscription error:', error);
      throw new Error('Failed to subscribe to newsletter. Please try again later.');
    }

    return { success: true };
  } catch (error: any) {
    // Re-throw the already subscribed error to maintain the specific message
    if (error.message === 'This email is already subscribed to our newsletter') {
      throw error;
    }
    
    // Log any other errors and throw a generic message
    console.error('Newsletter subscription error:', error);
    throw new Error('Failed to subscribe to newsletter. Please try again later.');
  }
};