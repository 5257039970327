import { useState } from "react";
import { ArrowRight } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { subscribeToNewsletter } from "@/utils/newsletter";

const MaintenancePage = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast({
        title: "Please Review",
        description: "Please enter your email address.",
        variant: "destructive",
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast({
        title: "Please Review",
        description: "Please enter a valid email address.",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await subscribeToNewsletter(email, 'footer');
      toast({
        description: "Thank you for subscribing to our newsletter!",
        className: "bg-emerald text-white border-none",
      });
      setEmail("");
    } catch (error: any) {
      if (error.message.includes("already subscribed")) {
        toast({
          title: "Please Review",
          description: "This email is already subscribed to our newsletter",
          className: "bg-slate text-white border-none",
        });
      } else {
        toast({
          title: "Please Review",
          description: error.message,
          variant: "destructive",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-offwhite flex items-center justify-center">
      <div className="max-w-lg p-8 text-center">
        <div className="text-navy text-4xl md:text-5xl lg:text-6xl font-playfair mb-12 whitespace-nowrap">
          CIO Capital Group
        </div>
        <p className="font-roboto text-navy mb-8">
          Subscribe to CIOC Insights & Commentary
        </p>
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-1 px-6 py-3 rounded bg-white border border-navy/20 text-navy placeholder:text-navy/60 focus:outline-none focus:ring-2 focus:ring-emerald"
              disabled={isSubmitting}
              required
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-emerald hover:bg-opacity-90 text-white px-8 py-3 rounded flex items-center justify-center gap-2 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Subscribe
              <ArrowRight size={20} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MaintenancePage;