import { useEffect } from "react";
import NewsletterHeader from "./newsletter/NewsletterHeader";
import NewsletterContent from "./newsletter/NewsletterContent";

const Newsletter = () => {
  useEffect(() => {
    const fromArticle = sessionStorage.getItem('fromArticle');
    if (fromArticle) {
      sessionStorage.removeItem('fromArticle');
      setTimeout(() => {
        const newsletterSection = document.getElementById("newsletter");
        if (newsletterSection) {
          newsletterSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, []);

  return (
    <section id="newsletter" className="py-20">
      <div className="container mx-auto px-4">
        <NewsletterHeader 
          title="Our Insights"
          description="CIO Capital Group delivers timely, data-driven perspectives to help you navigate the ever-evolving global markets. Our monthly newsletters provide in-depth analysis of economic trends, policy impacts, and investment strategies, complemented by concise updates on notable market events. These insights are designed to empower investors with actionable intelligence and a clear understanding of the forces shaping portfolios today and in the future."
        />
        <NewsletterContent />
      </div>
    </section>
  );
};

export default Newsletter;