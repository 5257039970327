import { MinimalChart } from "./MinimalChart";

interface DataCardProps {
  title: string;
  value: number | null;
  change?: number | null;
  chart?: Array<{ date: string; value: number }>;
  source: string;
  format?: string;
}

export const DataCard = ({ 
  title, 
  value, 
  change, 
  chart, 
  source, 
  format = "" 
}: DataCardProps) => {
  return (
    <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-200">
      <div className="flex justify-between items-start mb-4">
        <h3 className="font-playfair text-navy text-lg">{title}</h3>
        <span className="text-xs text-slate-400">{source}</span>
      </div>

      <div className="flex items-baseline gap-2 mb-4">
        <span className="text-2xl font-roboto">
          {value?.toFixed(2)}{format}
        </span>
        {change && (
          <span className={`text-sm ${change >= 0 ? 'text-emerald' : 'text-red-500'}`}>
            {change >= 0 ? '+' : ''}{change.toFixed(2)}%
          </span>
        )}
      </div>

      {chart && (
        <div className="h-32">
          <MinimalChart data={chart} />
        </div>
      )}
    </div>
  );
};