import { useEffect, useState } from "react";
import { Section } from "./Section";
import { DataCard } from "./DataCard";
import { API_CONFIG } from "@/config/api";

export const MacroIndicators = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchMacroData = async () => {
      try {
        // Simulated data for now - replace with actual API calls
        setData({
          gdp: {
            latest: 2.5,
            change: 0.3,
            historical: Array.from({ length: 10 }, (_, i) => ({
              date: new Date(Date.now() - i * 86400000).toISOString(),
              value: 2 + Math.random()
            }))
          },
          cpi: {
            latest: 3.1,
            change: -0.2,
            historical: Array.from({ length: 10 }, (_, i) => ({
              date: new Date(Date.now() - i * 86400000).toISOString(),
              value: 3 + Math.random()
            }))
          }
        });
      } catch (error) {
        console.error("Error fetching macro data:", error);
      }
    };
    
    fetchMacroData();
  }, []);

  return (
    <Section title="Economic Indicators">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DataCard
          title="US GDP Growth (YoY)"
          value={data?.gdp.latest}
          change={data?.gdp.change}
          chart={data?.gdp.historical}
          source="FRED"
          format="%"
        />
        
        <DataCard
          title="CPI"
          value={data?.cpi.latest}
          change={data?.cpi.change}
          chart={data?.cpi.historical}
          source="FRED"
          format="%"
        />
      </div>
    </Section>
  );
};