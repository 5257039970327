import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

interface NavigationArticle {
  article_number: number;
  title: string;
  publication_date: string;
}

interface ArticleNavigationProps {
  prevArticle: NavigationArticle | null;
  nextArticle: NavigationArticle | null;
}

const ArticleNavigation = ({ prevArticle, nextArticle }: ArticleNavigationProps) => {
  return (
    <div className="mt-16 flex justify-between items-center border-t border-lightgray pt-8">
      {prevArticle ? (
        <Link
          to={`/newsletter/${prevArticle.article_number}`}
          className="inline-flex items-center text-emerald hover:text-emerald/90 group"
        >
          <ChevronLeft className="h-4 w-4 mr-1 transition-transform group-hover:-translate-x-1" />
          <div>
            <div className="text-sm">Previous Article</div>
            <div className="text-xs text-slate">
              {format(new Date(prevArticle.publication_date), "MMM d, yyyy")}
            </div>
          </div>
        </Link>
      ) : (
        <div />
      )}

      {nextArticle ? (
        <Link
          to={`/newsletter/${nextArticle.article_number}`}
          className="inline-flex items-center text-emerald hover:text-emerald/90 group text-right"
        >
          <div>
            <div className="text-sm">Next Article</div>
            <div className="text-xs text-slate">
              {format(new Date(nextArticle.publication_date), "MMM d, yyyy")}
            </div>
          </div>
          <ChevronRight className="h-4 w-4 ml-1 transition-transform group-hover:translate-x-1" />
        </Link>
      ) : (
        <div />
      )}
    </div>
  );
};

export default ArticleNavigation;