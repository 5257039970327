import { format } from "date-fns";
import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

interface ArticleHeaderProps {
  title: string;
  publicationDate: string | null;
}

const ArticleHeader = ({ title, publicationDate }: ArticleHeaderProps) => {
  const handleBackClick = () => {
    // Store the fact that we're coming from an article
    sessionStorage.setItem('fromArticle', 'true');
  };

  return (
    <div className="mb-8">
      <Link
        to="/"
        className="inline-flex items-center text-emerald hover:text-emerald/90 mb-8"
        onClick={handleBackClick}
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Newsletters
      </Link>

      <h1 className="text-4xl font-playfair font-bold text-navy mt-2 mb-3">
        {title}
      </h1>

      {publicationDate && (
        <time className="block text-sm text-slate">
          {format(new Date(publicationDate), "MMMM d, yyyy")}
        </time>
      )}
    </div>
  );
};

export default ArticleHeader;