const MaintenanceBanner = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-emerald/90 text-white h-6 z-[100] flex items-center justify-center backdrop-blur-sm">
      <p className="font-roboto text-xs">
        Maintenance Mode Active - Admin Preview
        <button 
          onClick={() => window.location.reload()} 
          className="ml-4 px-2 py-0.5 bg-white/20 rounded hover:bg-white/30 transition-colors text-xs"
        >
          Refresh Page
        </button>
      </p>
    </div>
  );
};

export default MaintenanceBanner;