import ContactForm from "./contact/ContactForm";

interface ContactProps {
  onSubmitSuccess?: () => void;
}

const Contact = ({ onSubmitSuccess }: ContactProps) => {
  return <ContactForm onSubmitSuccess={onSubmitSuccess} />;
};

export default Contact;