interface DesktopNavProps {
  isScrolled: boolean;
  isArticlePage: boolean;
  onNavigate: (sectionId: string | null) => void;
  onContactClick: () => void;
}

const DesktopNav = ({
  isScrolled,
  isArticlePage,
  onNavigate,
  onContactClick,
}: DesktopNavProps) => {
  const navItemClass = `
    font-roboto px-4 py-2 rounded-lg transition-all duration-300
    ${isScrolled || isArticlePage ? "text-navy" : "text-white"}
    hover:bg-emerald/80 hover:text-white
  `;

  return (
    <div className="space-x-2">
      <button
        onClick={() => onNavigate(null)}
        className={navItemClass}
      >
        About Us
      </button>
      <button
        onClick={() => onNavigate('values')}
        className={navItemClass}
      >
        Values
      </button>
      <button
        onClick={() => onNavigate('newsletter')}
        className={navItemClass}
      >
        Insights
      </button>
      <button
        onClick={() => onNavigate('team')}
        className={navItemClass}
      >
        Team
      </button>
      <button 
        onClick={onContactClick}
        className={`font-roboto hover:bg-emerald/80 hover:text-white px-4 py-2 rounded-lg transition-all duration-300 focus:outline-none ${isScrolled || isArticlePage ? "text-navy" : "text-white"}`}
      >
        Contact
      </button>
    </div>
  );
};

export default DesktopNav;