import { useLocation } from "react-router-dom";
import { useAuthStatus } from "@/hooks/useAuthStatus";
import { useMaintenanceMode } from "@/hooks/useMaintenanceMode";
import MaintenancePage from "./MaintenancePage";
import MaintenanceBanner from "./MaintenanceBanner";

interface RouteGuardProps {
  children: React.ReactNode;
}

/**
 * RouteGuard Component
 * 
 * This component handles access control and maintenance mode for the application.
 * It provides the following functionality:
 * 
 * 1. Checks if the user is an admin
 * 2. Checks if the site is in maintenance mode
 * 3. Controls access to routes based on maintenance status and user role
 * 4. Shows maintenance page for non-admin users when maintenance mode is active
 * 5. Shows maintenance banner for admin users when maintenance mode is active
 * 
 * Special cases:
 * - The admin login page (/admin/login) is always accessible
 * - Admin users can access all routes even during maintenance
 * - Non-admin users see the maintenance page when maintenance mode is active
 */
const RouteGuard = ({ children }: RouteGuardProps) => {
  const location = useLocation();
  const { isAdmin, isLoading: authLoading } = useAuthStatus();
  const { inMaintenance, isLoading: maintenanceLoading } = useMaintenanceMode();

  // Add debug logging
  console.log("Maintenance mode:", inMaintenance);
  console.log("Current route:", location.pathname);
  console.log("Is admin:", isAdmin);

  // Show nothing while loading to prevent flash of content
  if (authLoading || maintenanceLoading) {
    return null;
  }

  // Allow access to admin login page even during maintenance
  if (location.pathname === "/admin/login") {
    return <>{children}</>;
  }

  // Handle maintenance mode
  if (inMaintenance) {
    if (isAdmin) {
      return (
        <>
          {children}
          <MaintenanceBanner />
        </>
      );
    }
    return <MaintenancePage />;
  }

  // Default case: allow access
  return <>{children}</>;
};

export default RouteGuard;