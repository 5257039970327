import { Dialog, DialogContent } from "@/components/ui/dialog";
import ReactMarkdown from 'react-markdown';

interface Newsletter {
  id: string;
  article_number: number;
  title: string;
  description: string | null;
  content: string;
  content_format: string;
  publication_date: string | null;
  status: "draft" | "published";
}

interface NewsletterPreviewProps {
  newsletter: Newsletter | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const NewsletterPreview = ({ newsletter, open, onOpenChange }: NewsletterPreviewProps) => {
  if (!newsletter) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
        <div className="space-y-4">
          <h2 className="text-2xl font-playfair font-bold text-navy">
            {newsletter.title}
          </h2>
          {newsletter.description && (
            <p className="text-slate">{newsletter.description}</p>
          )}
          <div className="prose">
            {newsletter.content_format === 'html' ? (
              <div 
                dangerouslySetInnerHTML={{ __html: newsletter.content }}
                className="prose"
              />
            ) : (
              <ReactMarkdown className="prose">
                {newsletter.content}
              </ReactMarkdown>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};