import { Footerdemo } from "@/components/ui/footer-section";

function Footer() {
  return (
    <div className="block">
      <Footerdemo />
    </div>
  );
}

export default Footer;