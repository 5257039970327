import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "lucide-react";
import useMobile from "@/hooks/use-mobile";
import { useScroll } from "@/hooks/use-scroll";
import { scrollToSection } from "@/utils/navigation";
import ContactSheet from "@/components/ContactSheet";
import { useContactForm } from "@/hooks/use-contact-form";
import MobileMenu from "./navbar/MobileMenu";
import DesktopNav from "./navbar/DesktopNav";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const isArticlePage = location.pathname.includes('/newsletter/');
  const { isOpen: contactIsOpen, setOpen: setContactOpen } = useContactForm();

  const isScrolled = useScroll(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleLogoClick = (e: React.MouseEvent) => {
    if (location.pathname === '/') {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleNavigation = (sectionId: string | null) => {
    scrollToSection(sectionId, location.pathname, navigate);
    setIsOpen(false);
  };

  const glassEffect = isArticlePage 
    ? "bg-white/80 shadow-md py-4"
    : isScrolled 
      ? "bg-white/80 shadow-md py-4" 
      : "bg-transparent py-6";

  return (
    <>
      <nav
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${glassEffect}`}
      >
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleLogoClick}
              className={`font-playfair text-xl ${
                isScrolled || isArticlePage ? "text-navy" : "text-white"
              }`}
            >
              CIO Capital Group
            </Link>

            {isMobile ? (
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`${
                  isScrolled || isArticlePage ? "text-navy" : "text-white"
                }`}
              >
                <Menu className="h-6 w-6" />
              </button>
            ) : (
              <DesktopNav
                isScrolled={isScrolled}
                isArticlePage={isArticlePage}
                onNavigate={handleNavigation}
                onContactClick={() => setContactOpen(true)}
              />
            )}
          </div>

          {isMobile && isOpen && (
            <MobileMenu
              isScrolled={isScrolled}
              isArticlePage={isArticlePage}
              onNavigate={handleNavigation}
              onContactClick={() => setContactOpen(true)}
            />
          )}
        </div>
      </nav>
      {contactIsOpen && (
        <ContactSheet 
          isOpen={contactIsOpen} 
          onOpenChange={setContactOpen}
        />
      )}
    </>
  );
};

export default Navbar;